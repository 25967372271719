import {API_BASE_URL} from '../constants'

import axios from 'axios';

export const createWorkspace = async (ownerId, name) => {
    const response = await axios.post(`${API_BASE_URL}/workspace/create?owner_id=${ownerId}`, { name });
    return response.data;
};

export const getWorkspaces = async (ownerId) => {
    const response = await axios.get(`${API_BASE_URL}/workspace/owner/all?owner_id=${ownerId}`);
    return response.data;
};

export const deleteWorkspace = async (workspaceId) => {
    const response = await axios.delete(`${API_BASE_URL}/workspace/${workspaceId}`);
    return response.data;
};