import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import rightDrawerSlice from '../features/common/rightDrawerSlice'
import workspaceSlice from '../features/settings/workspace/workspaceSlice'
import agentsSlice from '../features/agents/agentSlice'

const combinedReducer = {
  header : headerSlice,
  rightDrawer : rightDrawerSlice,
  modal : modalSlice,
  workspaces : workspaceSlice,
  agents : agentsSlice
}

export default configureStore({
    reducer: combinedReducer
})