import {API_BASE_URL} from '../constants'


export const createAgent = async (workspaceId, formData) => {
    const response = await fetch(`${API_BASE_URL}/agents/create/${workspaceId}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'accept': 'application/json'
        },
        body: JSON.stringify(formData)
    })
    return response;
};

export const getAllAgents = async (workspaceId) => {
    const response = await fetch(`${API_BASE_URL}/agents/all/${workspaceId}`, {
        method: 'GET',
        headers: {
            'accept': 'application/json'
        }
    });
    return response;
};

export const getAgentVoices = async () => {
    const response = await fetch(`${API_BASE_URL}/agents/voices/`, {
        method: 'GET',
        headers: {
            'accept': 'application/json'
        }
    });
    return response;
};

export const deleteAgent = async (agentId) => {
    const response = await fetch(`${API_BASE_URL}/agents/${agentId}`, {
        method: 'DELETE',
        headers: {
            'accept': 'application/json'
        }
    });
    return response;
};

export const updateAgent = async (agentId, agentData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/agents/${agentId}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify(agentData),
        });
        return response;
    } catch (error) {
        console.error('Error updating agent:', error);
        return { ok: false };
    }
};

export const fetchAccessToken = async (agentId, voiceAiAgentId) => {
    try {
        const response = await fetch(`${API_BASE_URL}/RegisterWebCall/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                agent_id: agentId,
                voice_ai_agent_id: voiceAiAgentId
            }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`HTTP error! status: ${response.status}, detail: ${errorData.detail}`);
        }

        const data = await response.json();
        console.log('Success:', data);
        return data;
    } catch (error) {
        console.error('Error:', error);
        return null;
    }
};