import {API_BASE_URL} from '../constants'

import axios from 'axios';

export const updateUserWorkspace = async (userId, workspaceId) => {
    const response = await axios.put(`${API_BASE_URL}/user/${userId}`, { current_workspace: workspaceId });
    return response.data;
};

export const getUserDetails = async (userId) => {
    const response = await axios.get(`${API_BASE_URL}/user/${userId}`);
    return response.data;
};