// src/features/agent/agentSlice.js

import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import { createAgent, updateAgent, deleteAgent, getAllAgents } from '../../api/agentsApi';

const initialState = {
  agents: [],
  currentAgent: null,
  status: 'idle', // idle | loading | succeeded | failed
  error: null
};

// Async thunk to fetch agents
export const fetchAgents = createAsyncThunk('agents/fetchAgents', async (workspaceId) => {
  const response = await getAllAgents(workspaceId);
  if (!response.ok) {
    throw new Error('Failed to fetch agents');
  }
  return response.json();
});

// Async thunk to create an agent
export const createAgentAsync = createAsyncThunk('agents/createAgent', async ({ workspaceId, agentData }) => {
  const response = await createAgent(workspaceId, agentData);
  if (!response.ok) {
    throw new Error('Failed to create agent');
  }
  return response.json();
});

export const updateAgents = (agents) => ({
  type: 'agents/updateAgents',
  payload: agents
});

// Async thunk to update an agent
export const updateAgentAsync = createAsyncThunk('agents/updateAgent', async ({ agentId, agentData }) => {
  const response = await updateAgent(agentId, agentData);
  if (!response.ok) {
    throw new Error('Failed to update agent');
  }
  return response.json();
});

// Async thunk to delete an agent
export const deleteAgentAsync = createAsyncThunk('agents/deleteAgent', async (agentId) => {
  const response = await deleteAgent(agentId);
  if (!response.ok) {
    throw new Error('Failed to delete agent');
  }
  return response.json();
});

const agentSlice = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setCurrentAgent: (state, action) => {
      state.currentAgent = action.payload;
    },
    setAgents: (state, action) => {

      // sort by created at
      action.payload.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      state.agents = action.payload;
    },
    clearCurrentAgent: (state) => {
      state.currentAgent = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAgents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAgents.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.agents = action.payload;
      })
      .addCase(fetchAgents.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createAgentAsync.fulfilled, (state, action) => {
        state.agents.push(action.payload);
      })
      .addCase(updateAgentAsync.fulfilled, (state, action) => {
        const index = state.agents.findIndex(agent => agent.id === action.payload.id);
        if (index !== -1) {
          state.agents[index] = action.payload;
        }
      })
      .addCase(deleteAgentAsync.fulfilled, (state, action) => {
        state.agents = state.agents.filter(agent => agent.id !== action.payload.id);
        state.currentAgent = null;
      });
  }
});

export const { setCurrentAgent, setAgents, clearCurrentAgent } = agentSlice.actions;

export default agentSlice.reducer;
